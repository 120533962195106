import React from "react";

const Projects = () => {
    return (
        <div className="contentBlock">
            <h1>Projects</h1>
            <p>This is a placeholder for projects I am working on or have worked on!</p>
        </div>
    )
}

export default Projects;