import React from "react";
import {Image} from 'react-bootstrap'

const Blog = () => {
    return (
        <div className="contentBlock">
            <h1><strong>Blog</strong></h1>
            <p>I'm working on restructuring this section so changes to come!</p>
            <h2><strong>2020 Year in Summary / Reflections from 28</strong></h2>
            <h5><em>Date published: January 1, 2021</em></h5>
            <p>Another year, another year older. For those that are more interested in a quick summary, I’ve included my high-level overview first.</p>
            <h4><strong>TL;DR</strong></h4>
            <h5>Grateful for:</h5>
            <ul>
                <li>Family - spent more time with my sister and mom</li>
                <li>Health - best physical and mental health I’ve ever been in</li>
                <li>Career - fortunate for Airbnb, great manager, and not being laid off</li>
            </ul>
            <h5>Lessons & Reminders:</h5>
            <ul>
                <li>Antifragility - become stronger through pain and suffering</li>
                <li>Mindfulness - controlling the monkey mind with meditation and etc</li>
                <li>Journey > Destination - mindset shift</li>
            </ul>
            <h5>Next year focus:</h5>
            <ul>
                <li>Travel (hopefully)</li>
                <li>Music/Video creation</li>
                <li>Communities (Online and IRL)</li>
            </ul>
            <h5>Top discoveries:</h5>
            <ul>
                <li>Options trading - COVID hobby</li>
                <li>Making ramen - bored and was craving ramen during SIP</li>
                <li>Ali Abdaal - found by looking for standing desk reviews</li>
            </ul>
            <h5>Favorite new artists:</h5>
            <ul>
                <li>Kllo</li>
                <li>Emotional Oranges</li>
                <li>Aiobahn</li>
            </ul>
            <h5>Favorite podcast interviews:</h5>
            <ul>
                <li>Naval Ravikant (<a href="https://tim.blog/2016/01/30/naval-ravikant-on-happiness-hacks/" rel="noopener noreferrer" target="_blank">1</a>,<a href="https://tim.blog/2020/10/14/naval/" rel="noopener noreferrer" target="_blank">2</a>)</li>
                <li>Nick Kokonas (<a href="https://tim.blog/2018/10/18/nick-kokonas/" rel="noopener noreferrer" target="_blank">1</a>,<a href="https://tim.blog/2020/05/06/nick-kokonas-2/" rel="noopener noreferrer" target="_blank">2</a>)</li>
                <li><a href="https://tim.blog/2015/12/14/derek-sivers-on-developing-confidence-finding-happiness-and-saying-no-to-millions/" rel="noopener noreferrer" target="_blank">Derek Sivers</a></li>
            </ul>
            <h5>Favorite anime:</h5>
            <ol>
                <li>Gleipnir</li>
                <li>Jujutsu Kaisen</li>
                <li>Tokyo Ghoul</li>
            </ol>
            <h5>Favorite words:</h5>
            <ul>
                <li>Convergence</li>
                <li>Serendipity</li>
                <li>Leverage</li>
            </ul>
            <h5><strong>Books (Ordered by influence on me this year)</strong></h5>
            <p>I plan on rereading and taking detailed notes of some in 2021, so summaries to come!</p>
            <h6>⭐⭐⭐⭐⭐ (5/5)</h6>
            <ul>
                <li>Antifragile - Nassim Nicholas Taleb</li>
                <li>Show Your Work! - Austin Kleon</li>
                <li>The Courage To Be Disliked - Ichiro Kishimi</li>
                <li>The Almanack of Naval Ravikant - Eric Jorgenson</li>
                <li>The 4-Hour Workweek - Timothy Ferriss</li>
                <li>Deep Work - Cal Newport</li>
                <li>Black Swan - Nassim Nicholas Taleb</li>
                <li>Fooled by Randomness - Nassim Nicholas Taleb</li>
                <li>Eat a Peach - David Chang</li>
                <li>Maybe You Should Talk to Someone - Lori Gottlieb</li>
                <li>Atomic Habit - James Clear</li>
                <li>Educated - Tara Westover</li>
                <li>A Visit from the Goon Squad - Jennifer Egan</li>
            </ul>
            <h6>⭐⭐⭐⭐ (4/5)</h6>
            <ul>
                <li>Work Rules! - Laszlo Bock</li>
                <li>How to Change Your Mind - Michael Pollan</li>
                <li>Give and Take - Adam Grant</li>
                <li>Start with Why - Simon Sinek</li>
                <li>The 80/20 Principle - Richard Koch</li>
            </ul>
            <h6>⭐⭐⭐ (3/5)</h6>
            <ul>
                <li>The Art of Gathering - Priya Parker</li>
                <li>10% Happier - Dan Harris</li>
            </ul>
            <h6>⭐⭐ (2/5)</h6>
            <ul>
                <li>The First 20 Hours - Josh Kaufman</li>
                <li>Ikigai - Yukari Mitsuhashi</li>
            </ul>
            <h4><strong>2020 Summary</strong></h4>
            <div className="quoteBlock"><p><em>“The first principle is that you must not fool yourself — and you are the easiest person to fool.” - Richard Feynman</em></p></div>
            <p>I’ve been fooling myself for the past few years. It was difficult to articulate what I wanted and I didn’t take the time to explore them more. Life moves so fast sometimes that it’s easier to run on autopilot. I started journaling and it gave me clarity. Last year (2020) ended up being a very pivotal year for me. I accomplished many of the things I set out to do. I hosted many dinners at Airbnb and at my apartment which allowed me to cook, bake, and socialize more. I’m probably in the best physical shape I’ve ever been in despite gyms closing through a combination of dieting, running, and home lifting. I was less successful at other things. Working less and staying home less were difficult due to the pandemic.</p>
            <h6>Here’s some relevant journal entries:</h6>
            <Image src="../../images/journalResolutions2020.jpg" alt="resolutions" className="resolution2020"/>
            <p>This is freaking hilarious. This is so me. I have not changed in some ways. Journaling has allowed me to look back in time and see how I was feeling or thinking. It’s like taking photos; you can look back at your journal entries and remind yourself of where you mentally were at a specific point in time. The most recent one about food hit so hard. I made a conscious decision to stop cooking for the last half of the year. During the same time period, I became the most productive in that year in pursuing my other interests. I love cooking. But cooking is very time consuming.</p>
            <div className="quoteBlock"><p><em>"Pain + Reflection = Progress" - Ray Dalio</em></p></div>
            <p>I’m thankful for the time I had this year to both cook and bake a lot. I’ve been wanting to spend more time on that for years, but it was difficult when one gets free food at work. As much as I wanted to explore food creation, I didn’t realize (at the time) what the opportunity cost would be. A constant theme arose in my journal entries. I realized that food creation was holding me back in other areas of growth. Reflecting allows one to shorten the feedback loop in growth. Writing allows more structure and detailed reflecting.</p>
            <div className="picsParent">
                <Image src="../../images/ramenTanTan.jpg" alt="Ramen" className="pics"/>
                <Image src="../../images/yakitori.jpg" alt="Yakitori" className="pics"/>
                <Image src="../../images/hotChicken.jpg" alt="Hot Chicken" className="pics"/>
            </div>
            <div className="picsParent">
                <Image src="../../images/ramenNoodles.jpg" alt="Ramen Noodles" className="pics"/>
                <Image src="../../images/matchaCrepeCake.jpg" alt="Crepe Cake" className="pics"/>
                <Image src="../../images/matchaPie.jpg" alt="Pie" className="pics"/>
            </div>
            <p> </p>
            <p>Daily journaling positively impacted my life this year. I used to hate writing. I probably still hate writing. In high school, writing had a different purpose. I was writing to do well in school and climb up the social and academic hierarchy. Today I am writing because it allows me to structure and revise my thoughts. I am able to better share and connect with people. Writing also acts as a form of active recall to internalize concepts and ideas better. I gain better intuition on a particular subject or thought when I attempt to articulate it to other people.</p>
            <p>This year was a reminder that it really is never too late to start something. There is always this fear in me that I am starting something later than most of my peers, but the earlier I can overcome that the more fulfilled I will be. I turned 29 today and my life is just beginning (or I like to fool myself). My plan is to continue daily journaling. I have a long journey ahead of me in improving my writing. In 2020, journaling was my primary driver for writing. I intend on writing more longer form blog content this year as another mechanism to improve my thinking and writing.</p>
            <h4><strong>Bonus Content</strong></h4>
            <p>I started running outside in 2019 and ramped that up in 2020 (~3 miles/week). I want to train up my mileage to be able to run a half marathon distance by mid year and full marathon distance by the end of the year. My goal for 2021 is to run an average of 5 miles per week (260 miles).</p>
            <div className="picsParent">
                <Image src="../../images/runChange.jpg" alt="Strava" className="bonusPics"/>
            </div>
            <p> </p>
            <p>Below is my Fitbit steps tracking. It's interesting to see that my average steps per day dropped from ~13k to ~12k. Taking into account that 2020 was spent mostly indoors, this makes sense.</p>
            <div className="picsParent">
                <Image src="../../images/stepsChange.jpg" alt="Fitbit Steps" className="bonusPics"/>
            </div>
            <p> </p>
            <p>Lastly, my weight tracking from the last two years is below. I track my weight most mornings via my bluetooth scale. I began and ended the same weight in 2019 and lost 21.5 lbs in 2020. My lowest weight in 2019 was 163.4 lbs and I remember that I started gaining weight when I joined Airbnb 2 weeks later. Once quarantine started, I made a conscious effort to lose weight again. My initial goal was to hit 160 by the end of the year but then shifted that to 155 lbs as a stretch goal. My goal for 2021 is to cut down to 150 lbs by July and then bulk back up to 180 lbs if I am successful.</p>
            <div className="picsParent">
                <Image src="../../images/weightChange.jpg" alt="Weight" className="bonusPics"/>
            </div>
        </div>
    )
}

export default Blog;