import React from "react";

const Food = () => {
    return (
        <div className="contentBlock">
            <h1>Food</h1>
            <p>This is a placeholder for food photos.</p>
        </div>
    )
}

export default Food;