import React from "react";

const Travel = () => {
    return (
        <div className="contentBlock">
            <h1>Travel</h1>
            <p>This is a placeholder for travel photos.</p>
        </div>
    )
}

export default Travel;